import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import projectType from "../../utils/project-types"

const PortfolioList = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressWpPortfolio(sort: { fields: crb_project_date, order: DESC }) {
        edges {
          node {
            wordpress_id
            title
            crb_client_name
            crb_project_date
            crb_project_url
            crb_project_type
            slug
            featured_media {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div
      className={
        "bg-blue-50 bg-no-repeat bg-pofo bg-pofo-p lg:bg-pofo-p-lg xl:bg-pofo-p-xl bg-pofo-s md:bg-pofo-s-lg xl:bg-pofo-s-xl pt-16 pb-24 px-8 sm:px-28 lg:px-28 xl:px-60"
      }
      id="portfolio"
    >
      <h2 className={"text-center pt-2 pb-14"}>
        Work that stands out from the crowd
      </h2>
      <div
        className={
          "container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
        }
      >
        {data.allWordpressWpPortfolio.edges.map(post => (
          <Link
            to={`/portfolio/${post.node.slug}`}
            key={post.node.wordpress_id}
            className={"flex flex-col hover:bg-pink pofo"}
          >
            <Img
              className={
                "bg-white object-contain flex flex-grow h-40 md:h-80 py-40 sm:py-0"
              }
              fluid={post.node.featured_media.localFile.childImageSharp.fluid}
              alt={post.node.featured_media.title}
              imgStyle={{ objectFit: "contain" }}
            />
            <div
              className={"font-sans text-center sm:text-left text-sm pt-4 pl-4"}
            >
              {projectType(post.node.crb_project_type)}
            </div>
            <h3
              className={
                "font-serif text-center sm:text-left text-xl sm:text-base pl-4 pt-1 pb-4"
              }
              dangerouslySetInnerHTML={{ __html: post.node.title }}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default PortfolioList
