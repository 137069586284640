import React from "react"

import webDesign from "../images/services/web-design.svg"
import appDevelopment from "../images/services/app-development.svg"
import seo from "../images/services/seo.svg"

const Services = () => {
  return (
    <div
      className={
        "bg-blue-50 bg-no-repeat bg-services bg-services-p lg:bg-services-p-lg xl:bg-services-p-xl bg-services-s lg:bg-services-s-lg xl:bg-services-s-xl pt-16 pb-24 sm:pt-24 px-8 sm:px-28 lg:px-28 xl:px-60"
      }
      id="services"
    >
      <div className={"container mx-auto flex flex-col lg:flex-row"}>
        <h2 className={"pt-4 pb-14 lg:w-72 text-center lg:text-left"}>
          Modern design and high quality code
        </h2>
        <div className="flex flex-1 flex-col justify-end lg:pl-24">
          {/* Web Design */}
          <div
            className={
              "bg-white flex text-center md:text-left pt-8 md:pt-12 pb-12 mb-8"
            }
          >
            <div className={"border-l-2 border-green px-10"}>
              <h3>Web Design</h3>
              <p className={"text-gray-600 pt-4 mb-0"}>
                Over 10 years of experience designing new business websites to
                custom e-commerce stores and everything In-between. Sites that
                look just as beautiful on phones as they do on desktops.
              </p>
            </div>
            <div
              className={"hidden md:flex items-center m-auto md:m-0 md:pr-10"}
            >
              <img
                className={"w-72"}
                src={webDesign}
                alt="Long Island Web Designer"
              />
            </div>
          </div>

          {/* App Development */}
          <div
            className={
              "bg-white flex text-center md:text-left pt-8 md:pt-12 pb-12 mb-8"
            }
          >
            <div className={"border-l-2 border-green px-10"}>
              <h3>App Development</h3>
              <p className={"text-gray-600 pt-4 mb-0"}>
                Apps provide a ton of benefits over mobile websites. Tapping
                into all the features the devices have to offer, I’ve created
                amazing apps covering all areas: iOS and Android, native and
                hybrid, phones and tablets.
              </p>
            </div>
            <div
              className={"hidden md:flex items-center m-auto md:m-0 md:pr-10"}
            >
              <img
                className={"w-40"}
                src={appDevelopment}
                alt="Long Island Mobile App Developer"
              />
            </div>
          </div>

          {/* SEO */}
          <div
            className={
              "bg-white flex text-center md:text-left pt-8 md:pt-12 pb-12"
            }
          >
            <div className={"border-l-2 border-green px-10"}>
              <h3>Search Engine Optimization</h3>
              <p className={"text-gray-600 pt-4 mb-0"}>
                Increase your web traffic, leads, and sales utilizing my proven
                strategies including web content optimization, local citation
                optimization (local SEO), conversion rate optimization, link
                building, and analytics reporting.
              </p>
            </div>
            <div
              className={"hidden md:flex items-center m-auto md:m-0 md:pr-10"}
            >
              <img className={"w-72"} src={seo} alt="Long Island SEO" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
