import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Testimonials from "../components/testimonials.component"
import PortfolioList from "../components/portfolio/portfolio-list.component"
import ClientLogos from "../components/client-logos.component"
import Services from "../components/services.component"
import AboutMe from "../components/about-me.component"
import Contact from "../components/contact.component"
import BackgroundImage from "gatsby-background-image"

const IndexPage = ({ data }) => {
  const { pinkDiamond, greenDiamond, greenPlus } = useStaticQuery(
    graphql`
      query {
        pinkDiamond: file(relativePath: { eq: "bg/pink-diamond.svg" }) {
          publicURL
        }
        greenDiamond: file(relativePath: { eq: "bg/green-diamond.svg" }) {
          publicURL
        }
        greenPlus: file(relativePath: { eq: "bg/green-plus.svg" }) {
          publicURL
        }
      }
    `
  )

  /*  const backgroundFluidImageStack = [
    `url(${mdGreenDiamond.publicURL})`,
    `url(${mdGreenPlus.publicURL})`,
    "url(https://res.cloudinary.com/allankiezel/image/upload/f_auto,q_auto/v1613734809/website/allan-kiezel-web-developer_ujqey1.png)",
  ].reverse() */

  return (
    <Layout>
      <SEO />
      <div
        className={
          "bg-blue-50 bg-no-repeat bg-hero-hp bg-hero-p lg:bg-hero-p-lg xl:bg-hero-p-xl bg-hero-s md:bg-hero-s-lg xl:bg-hero-s-xl py-32 sm:py-36 lg:py-40 px-8 sm:px-28 lg:px-28 xl:px-60 relative"
        }
      >
        <div
          className={
            "container mx-auto lg:mx-0 max-w-lg lg:max-w-lg xl:max-w-xl"
          }
        >
          <div className={"font-serif text-black text-2xl pb-2 xl:pb-3"}>
            Hello, I'm
          </div>
          <div
            className={
              "font-serif text-pink text-5xl sm:text-6xl lg:text-7xl xl:text-5.5xl"
            }
          >
            Allan Kiezel
          </div>
          <p className={"pt-3 xl:pt-5 lg:pr-5 mb-0"}>
            a Long Island web designer and app developer with
            over 15 years of experience creating beautiful
            websites and mobile applications.
          </p>
          <a
            href="#contact"
            className="bg-green hover:bg-green-dark text-white py-4 px-10 mt-6 rounded inline-block"
          >
            Contact Me
          </a>
        </div>
        <img
          src={pinkDiamond.publicURL}
          className={"absolute -left-7 -bottom-10 sm:-bottom-14 w-20 sm:w-28"}
        />
      </div>
      <ClientLogos></ClientLogos>
      <PortfolioList></PortfolioList>
      <Testimonials></Testimonials>
      <Services></Services>
      <AboutMe></AboutMe>
      <Contact></Contact>
      {/* <BackgroundImage
        Tag={`section`}
        className={
          "bg-blue-50 bg-hero-allan bg-hero xl:bg-hero-xl py-32 sm:py-36 lg:py-40 px-8 sm:px-28 lg:px-28 xl:px-60"
        }
        fluid={backgroundFluidImageStack}
        style={{
          // Defaults are overwrite-able by setting one or each of the following:
          backgroundSize: "",
          backgroundPosition: "",
          backgroundRepeat: "",
        }}
      >
        <div
          className={
            "container mx-auto lg:mx-0 max-w-lg lg:max-w-lg xl:max-w-xl"
          }
        >
          <div className={"font-serif text-black text-2xl pb-0 xl:pb-3"}>
            Hello, I'm
          </div>
          <div
            className={
              "font-serif text-pink text-5xl sm:text-6xl lg:text-7xl xl:text-5.5xl"
            }
          >
            Allan Kiezel
          </div>
          <p className={"pt-0 xl:pt-5 lg:pr-5"}>
            a multi-disciplinary web designer based out of Long Island, NY with
            over 10 years of experience creating beautiful and functional
            websites and mobile applications.
          </p>
          <a
            href=""
            className="bg-green hover:bg-green-dark text-white py-4 px-10 mt-6 rounded inline-block"
          >
            Hire Me
          </a>
        </div>
      </BackgroundImage> */}
    </Layout>
  )
}

export default IndexPage

/* export const query = graphql`
  query {

  }
` */
