import React from "react"

// Client Logos
import clientLogoSBU from "../images/client-logo-stony-brook-university.svg"
import clientLogoCAC from "../images/client-logo-california-avocados.svg"
import clientFR from "../images/client-logo-francesco-rinaldi.svg"
import clientKD from "../images/client-logo-kids-discover.svg"
import clientNASA from "../images/client-logo-nasa.svg"
import clientAN from "../images/client-logo-ancient-nutrition.svg"
import clientDrAxe from "../images/client-logo-dr-axe.svg"
import clientMbg from "../images/client-logo-mindbodygreen.svg"

const ClientLogos = () => {
  return (
    <div className={"pt-10 pb-8 px-8 sm:px-28 lg:px-28 xl:px-60"}>
      <div
        className={
          "container mx-auto flex flex-wrap justify-between items-center"
        }
      >
        <img
          className={"h-16 mb-6 sm:mb-2 w-1/2 sm:w-auto"}
          src={clientLogoSBU}
          alt="Client Stony Brook University"
        />
        <img
          className={"h-12 mb-6 sm:mb-2 hidden lg:block"}
          src={clientLogoCAC}
          alt="Client California Avocado Commission"
        />
        <img
          className={"h-12 mb-6 sm:mb-2 hidden md:block"}
          src={clientFR}
          alt="Client Francesco Rinaldi"
        />
        <img
          className={"h-6 mb-6 sm:mb-2 w-1/2 sm:w-auto"}
          src={clientDrAxe}
          alt="Client Dr. Axe"
        />
        <img
          className={"h-12 mb-6 sm:mb-2 w-1/2 sm:w-auto"}
          src={clientNASA}
          alt="Client NASA"
        />
        <img
          className={"h-12 mb-6 sm:mb-2 w-1/2 sm:w-auto hidden lg:block"}
          src={clientKD}
          alt="Client KIDS DISCOVER"
        />
        <img
          className={"h-6 mb-6 sm:mb-2 w-1/2 sm:w-auto"}
          src={clientMbg}
          alt="Client mindbodygreen"
        />
      </div>
    </div>
  )
}

export default ClientLogos
