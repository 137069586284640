import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const AboutMe = () => {
  const { pinkDiamond } = useStaticQuery(
    graphql`
      query {
        pinkDiamond: file(relativePath: { eq: "bg/pink-diamond.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <div
      className={
        "bg-black bg-no-repeat py-24 px-8 sm:px-28 lg:px-28 xl:px-60 relative"
      }
    >
      <div className={"container mx-auto flex flex-row flex-wrap text-white"}>
        <div className={"flex-auto lg:flex-2 mr-0 lg:mr-28 mb-8 lg:mb-0"}>
          <h2 className={"text-white pb-3"}>More about me</h2>
          <p className={"mb-0"}>
            As a former VP of Engineering for one of the largest educational
            software companies in the nation, I have a breadth of knowledge in
            Web and App Design, as well as deploying applications used by
            millions.
          </p>
        </div>
        <div className={"flex-1 min-w-160 pt-3"}>
          <div className={"border-b-1 border-gray-600 mb-2"}>Focus On</div>
          <ul>
            <li className={"mb-0.5 pr-6"}>Web Design</li>
            <li className={"mb-0.5 pr-6"}>App Development</li>
            <li className={"mb-0.5 pr-6"}>Web Applications</li>
            <li className={"mb-0.5 pr-6"}>PPC/SEO</li>
          </ul>
        </div>
        <div className={"flex-1 min-w-160 pt-3"}>
          <div className={"border-b-1 border-gray-600 mb-2"}>Frameworks</div>
          <ul>
            <li className={"mb-0.5 pr-6"}>Laravel</li>
            <li className={"mb-0.5 pr-6"}>WordPress</li>
            <li className={"mb-0.5 pr-6"}>React</li>
            <li className={"mb-0.5 pr-6"}>Ionic</li>
          </ul>
        </div>
        <div className={"hidden md:block flex-1 min-w-160 pt-3"}>
          <div className={"border-b-1 border-gray-600 mb-2"}>Hobbies</div>
          <ul>
            <li className={"mb-0.5 pr-6"}>Brazilian Jiu-Jitsu</li>
            <li className={"mb-0.5 pr-6"}>Food Photography</li>
            <li className={"mb-0.5 pr-6"}><a href="https://www.fattybuttsbbq.com/" target="_blank" title="BBQ Recipes">BBQ</a></li>
            <li className={"mb-0.5 pr-6"}>Exercising</li>
          </ul>
        </div>
      </div>
      <img
        src={pinkDiamond.publicURL}
        className={
          "absolute right-10 sm:right-20 -top-10 sm:-top-14 w-20 sm:w-28"
        }
      />
    </div>
  )
}

export default AboutMe
