import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"

// Miscellaneous Images
import fiveStars from "../images/five-stars.svg"

import "../components/slick/slick.css"
import "../components/slick/slick-theme.css"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query PhotoQuery {
      robert: file(
        relativePath: { eq: "reviews/robert-hernandez-review.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      audra: file(relativePath: { eq: "reviews/audra-defalco-review.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dan: file(relativePath: { eq: "reviews/dan-stone-review.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sliderSettings = {
    autoplay: false,
    autoplaySpeed: 8000,
    dots: true,
    infinite: true,
    fade: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div
      className={
        "bg-no-repeat bg-reviews bg-reviews-p lg:bg-reviews-p-lg xl:bg-reviews-p-xl bg-reviews-s sm:bg-reviews-s-lg pt-16 pb-32 px-8 sm:px-28 lg:px-40 xl:px-72"
      }
      id="testimonials"
    >
      <h2 className={"text-center pt-2 pb-14"}>
        Don't just take my word for it
      </h2>
      <div className={"container mx-auto shadow-review"}>
        <Slider {...sliderSettings}>
          {/* Audra */}
          <div className="bg-white flex-important flex-col md:flex-row items-center justify-between md:h-80">
            <div
              className={
                "w-full md:w-3/5 text-center md:text-left pt-8 md:pt-12 pb-12 order-2 md:order-1"
              }
            >
              <div className={"md:border-l-2 border-green px-10"}>
                <img
                  className={"h-4 m-auto md:m-0"}
                  src={fiveStars}
                  alt="Five Star Review"
                />
                <p className={"text-gray-600 pt-4 mb-0"}>
                  Allan is completely professional and prompt! I came to him
                  initially because I liked his portfolio and that he was local
                  to my area. I gave him a vision for a website and he more than
                  delivered. I would recommend him for web design and SEO
                  services to anyone.
                </p>
                <span className={"text-gray-800 block text-lg pt-3"}>
                  Audra DeFalco
                </span>
                <span className={"text-gray-600 block text-sm"}>
                  CEO, Get Italian Citizenship
                </span>
              </div>
            </div>
            <div
              className={
                "flex items-center m-auto md:m-0 md:pr-10 order-1 md:order-2"
              }
            >
              <Img
                className={"rounded-full w-40 md:w-52 mt-12 md:mt-0"}
                fluid={data.audra.childImageSharp.fluid}
                alt="Audra DeFalco - Web Design Review"
              />
            </div>
          </div>

          {/* Robert Hernandez */}
          <div className="bg-white flex-important flex-col md:flex-row items-center justify-between md:h-80">
            <div
              className={
                "w-full md:w-3/5 text-center md:text-left pt-8 md:pt-12 pb-12 order-2 md:order-1"
              }
            >
              <div className={"md:border-l-2 border-green px-10"}>
                <img
                  className={"h-4 m-auto md:m-0"}
                  src={fiveStars}
                  alt="Five Star Review"
                />
                <p className={"text-gray-600 pt-4 mb-0"}>
                  If you are looking for someone who is multi-talented in many
                  aspects of our new world of Digital Technology start with a
                  simple email and see where it takes you......you will be
                  pleased!!!!!
                </p>
                <span className={"text-gray-800 block text-lg pt-3"}>
                  Robert Hernandez
                </span>
                <span className={"text-gray-600 block text-sm"}>
                  CEO, Invent-On-Time
                </span>
              </div>
            </div>
            <div
              className={
                "flex items-center m-auto md:m-0 md:pr-10 order-1 md:order-2"
              }
            >
              <Img
                className={"rounded-full w-40 md:w-52 mt-12 md:mt-0"}
                fluid={data.robert.childImageSharp.fluid}
                alt="Robert Hernandez - Mobile App Development Review"
              />
            </div>
          </div>

          {/* Dan Stone */}
          <div className="bg-white flex-important flex-col md:flex-row items-center justify-between md:h-80">
            <div
              className={
                "w-full md:w-3/5 text-center md:text-left pt-8 md:pt-12 pb-12 order-2 md:order-1"
              }
            >
              <div className={"md:border-l-2 border-green px-10"}>
                <img
                  className={"h-4 m-auto md:m-0"}
                  src={fiveStars}
                  alt="Five Star Review"
                />
                <p className={"text-gray-600 pt-4 mb-0"}>
                  WHERE THE RUBBER MEETS THE ROAD... Allan is an exceptional
                  person - talented, thorough, honest, and decent throughout the
                  challenging course of programming our YIM! app (check it out
                  for yourself at the App Store) and is an integral part of our
                  plans for the future.
                </p>
                <span className={"text-gray-800 block text-lg pt-3"}>
                  Dan Stone
                </span>
                <span className={"text-gray-600 block text-sm"}>CEO, YIM!</span>
              </div>
            </div>
            <div
              className={
                "flex items-center m-auto md:m-0 md:pr-10 order-1 md:order-2"
              }
            >
              <Img
                className={"w-40 md:w-52 mt-12 md:mt-0"}
                fluid={data.dan.childImageSharp.fluid}
                alt="Dan Stone - Mobile App Development Review"
              />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default Testimonials
